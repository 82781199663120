export const environment = {
  production: true,
  apiRootUrl: 'https://agent-api.remedyoncall.com/',
  appVersion: 1,
  iframeSrc: 'https://boltgw.cardconnect.com/itoke/ajax-tokenizer.html',
  firebase: {
   apiKey: "AIzaSyDCJmoGIA8gcsem94gSeZhfs_7nod7uRGA",
   authDomain: "remedy-c8d05.firebaseapp.com",
   databaseURL: "https://remedy-c8d05.firebaseio.com",
   projectId: "remedy-c8d05",
   storageBucket: "remedy-c8d05.appspot.com",
   messagingSenderId: "615325739502",
   appId: "1:615325739502:web:3931ca779556a2f65e86ff",
   measurementId: "G-HFEDHCLDLF"
  }
};
