import { Component} from '@angular/core';

@Component({
    selector: 'app-footer-bar',
    templateUrl: './footer-bar.component.html',
})
export class FooterBarComponent { 
    date  : any = new Date();
    constructor() {
    }
}
